body {
  margin: 0;
  padding: 0;
  font-family:"Poppins",sans-serif;
  letter-spacing:0.9px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.link {
  text-decoration: none;
  color: white; 
}
.dues {
  font-weight: bold;
  color: red;
}
.slogan {
  font-family:'Times New Roman', Times, serif;
 font-weight: 900;
 background: linear-gradient(to right, yellow, wheat,green, #4286f4);
  -webkit-background-clip: text; /* Apply gradient to text */
  -webkit-text-fill-color: transparent; /* Hide original text color */
}
.custom-loader {
  width:50px;
  height:50px;
  border-radius:50%;
  padding:1px;
  background:conic-gradient(#0000 10%,#2196f3) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:s4 1s infinite steps(10);
}
@keyframes s4 {to{transform: rotate(1turn)}}
div#google_translate_element #goog-te-combo {
  /* Replace 'red' with your desired color */
  color: whitesmoke;
}
div#google_translate_element div.goog-te-gadget-simple {
  font-size: small;
}
div#google_translate_element div.goog-te-gadget-simple {
  background-color: #2196f3;
}
div#google_translate_element div.goog-te-gadget-simple a.goog-te-menu-value span {
 color: whitesmoke;
}

div#google_translate_element div.goog-te-gadget-simple a.goog-te-menu-value span:hover {
  color: whitesmoke;
}

div#google_translate_element div.goog-te-gadget-simple {
  border: none;
  color: white;
}
.custom-select {
  width: 100%;
  background-color: white;
  border: 1px wheat solid;
  padding: 12px 0px;
  font-family:"Poppins",sans-serif;
  font-size: medium;
  border-radius: 5px;
}  

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-100%); /* Start off-screen */
  }
  100% {
    transform: translateX(100%); /* Move to the left */
  }
}
.wrapper{
  position: fixed; /* Use fixed positioning to prevent scrolling */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000435;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.custom-loaderrr {
  width:50px;
  height:50px;
  border:2px solid white;
  display: grid;
  animation: h1 4s infinite linear;
}
.custom-loaderrr::before,
.custom-loaderrr::after {
  content: "";
  grid-area: 1/1;
  margin:auto;
  width:70.7%;
  height:70.7%; 
  border:2px solid white;
  animation: inherit;
}
.custom-loaderrr::after {
  width:50%;
  height:50%;
  border:2px solid white;
  animation-duration: 2s;
}
@keyframes h1{
  100% {transform:rotate(1turn)}
}